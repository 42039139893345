<app-header-nav current_tab="/feature-changes" show_model_select="true"></app-header-nav>
<div class="page-container">
  <div class="embed-page-container">
    <app-titles
      title="Top Feature Additions & Removals"
      subtitle="View the features that would affect overall series volume & revenue the most if added to the baseline and the least if removed from the baseline"
      action="Utilize filters to select a category or view all categories at once, switch between volume & revenue measures, and use segmentation filters to view influence on specific customer segments"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/5FeatureChangesEmbed2"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/5FeatureChangesEmbed2"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/5FeatureChangesEmbed2"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/5FeatureChangesEmbed2"/>
    <app-embed *ngIf="selected_model === 'RX'" src="{{path_RX}}/5FeatureChangesEmbed2"/>

  </div>
  <div class="embed-page-container">
    <app-titles
      title="Customer Willingness to Switch"
      subtitle="Matrix that shows where customers would switch to if a feature was not available (includes walk-away which highlights % of customers that would be at risk if the feature was removed)"
      action="Utilize filters to select a specific category, and use segmentation filters to view switching behavior of specific customer segments"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/5FeatureChangesEmbed22"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/5FeatureChangesEmbed22"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/5FeatureChangesEmbed22"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/5FeatureChangesEmbed22"/>
    <app-embed *ngIf="selected_model === 'RX'" src="{{path_RX}}/5FeatureChangesEmbed22"/>
  </div>
</div>
