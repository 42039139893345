import { Component } from '@angular/core';
import {DEFAULT_VEHICLE, INSIGHTS_CAROUSEL} from '../../config/config';
import {UIStateService} from '../../services/ui-state.service';
import {getBasePath} from '../../config/getInsightsBasePath';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css'],
})
export class OpportunitiesComponent {
  insights_carousel = INSIGHTS_CAROUSEL;
  claims;
  path_311D: string;
  path_311D_OP: string;
  path_400D: string;
  path_SIENNA: string;
  path_RX: string;
  selected_model: string;
  constructor(
    readonly appState: UIStateService
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        this.selected_model = state.vehicle ? state.vehicle : DEFAULT_VEHICLE;
      });
    const basePath = getBasePath();
    this.path_311D = basePath['311D'];
    this.path_311D_OP = basePath['311D_OP'];
    this.path_400D = basePath['400D'];
    this.path_SIENNA = basePath['SIENNA'];
    this.path_RX = basePath['RX']
  }
}
